.grid-container .right-button-panel {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.grid-container .right-button-panel button {
  background-color: #333847 !important;
  color: #fff;
  text-transform: none;
}
